// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-exhibitions-index-js": () => import("./../../../src/pages/exhibitions/index.js" /* webpackChunkName: "component---src-pages-exhibitions-index-js" */),
  "component---src-pages-how-we-know-what-we-know-index-js": () => import("./../../../src/pages/how-we-know-what-we-know/index.js" /* webpackChunkName: "component---src-pages-how-we-know-what-we-know-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-chapter-chapter-jsx": () => import("./../../../src/templates/chapter/chapter.jsx" /* webpackChunkName: "component---src-templates-chapter-chapter-jsx" */),
  "component---src-templates-cluster-cluster-jsx": () => import("./../../../src/templates/cluster/cluster.jsx" /* webpackChunkName: "component---src-templates-cluster-cluster-jsx" */),
  "component---src-templates-constellation-constellation-jsx": () => import("./../../../src/templates/constellation/constellation.jsx" /* webpackChunkName: "component---src-templates-constellation-constellation-jsx" */),
  "component---src-templates-constellation-group-constellation-group-jsx": () => import("./../../../src/templates/constellationGroup/constellationGroup.jsx" /* webpackChunkName: "component---src-templates-constellation-group-constellation-group-jsx" */),
  "component---src-templates-constellation-object-constellation-object-jsx": () => import("./../../../src/templates/constellationObject/constellationObject.jsx" /* webpackChunkName: "component---src-templates-constellation-object-constellation-object-jsx" */),
  "component---src-templates-detail-detail-jsx": () => import("./../../../src/templates/detail/detail.jsx" /* webpackChunkName: "component---src-templates-detail-detail-jsx" */),
  "component---src-templates-elevator-elevator-jsx": () => import("./../../../src/templates/elevator/elevator.jsx" /* webpackChunkName: "component---src-templates-elevator-elevator-jsx" */),
  "component---src-templates-exhibit-exhibit-jsx": () => import("./../../../src/templates/exhibit/exhibit.jsx" /* webpackChunkName: "component---src-templates-exhibit-exhibit-jsx" */),
  "component---src-templates-pillar-pillar-jsx": () => import("./../../../src/templates/pillar/pillar.jsx" /* webpackChunkName: "component---src-templates-pillar-pillar-jsx" */),
  "component---src-templates-ptp-entry-ptp-entry-jsx": () => import("./../../../src/templates/ptpEntry/ptpEntry.jsx" /* webpackChunkName: "component---src-templates-ptp-entry-ptp-entry-jsx" */),
  "component---src-templates-resources-resources-jsx": () => import("./../../../src/templates/resources/resources.jsx" /* webpackChunkName: "component---src-templates-resources-resources-jsx" */),
  "component---src-templates-resources-topic-resources-topic-jsx": () => import("./../../../src/templates/resourcesTopic/resourcesTopic.jsx" /* webpackChunkName: "component---src-templates-resources-topic-resources-topic-jsx" */),
  "component---src-templates-stories-landing-stories-landing-jsx": () => import("./../../../src/templates/storiesLanding/storiesLanding.jsx" /* webpackChunkName: "component---src-templates-stories-landing-stories-landing-jsx" */),
  "component---src-templates-story-story-jsx": () => import("./../../../src/templates/story/story.jsx" /* webpackChunkName: "component---src-templates-story-story-jsx" */)
}

